define("discourse/plugins/discourse-coupa-people/discourse/templates/connectors/discovery-list-container-top/forum-heading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showHeading}}
    <h1>{{i18n "coupa_people.forums"}}</h1>
  {{/if}}
  */
  {
    "id": "5j/FkWht",
    "block": "[[[41,[30,0,[\"showHeading\"]],[[[1,\"  \"],[10,\"h1\"],[12],[1,[28,[35,2],[\"coupa_people.forums\"],null]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"h1\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-coupa-people/discourse/templates/connectors/discovery-list-container-top/forum-heading.hbs",
    "isStrictMode": false
  });
});