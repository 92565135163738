define("discourse/plugins/discourse-coupa-people/discourse/templates/connectors/user-location-and-website/linkedin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.website}}
    <div class="user-profile-linkedin">
      {{d-icon "fab-linkedin"}}
      <a
        href={{this.model.website}}
        rel="noopener noreferrer"
        target="_blank"
      >{{this.model.website_name}}</a>
    </div>
  {{/if}}
  */
  {
    "id": "uGO+YIdt",
    "block": "[[[41,[30,0,[\"model\",\"website\"]],[[[1,\"  \"],[10,0],[14,0,\"user-profile-linkedin\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"fab-linkedin\"],null]],[1,\"\\n    \"],[10,3],[15,6,[30,0,[\"model\",\"website\"]]],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[12],[1,[30,0,[\"model\",\"website_name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"d-icon\",\"a\"]]",
    "moduleName": "discourse/plugins/discourse-coupa-people/discourse/templates/connectors/user-location-and-website/linkedin.hbs",
    "isStrictMode": false
  });
});